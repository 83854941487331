/**
 * 获取触发源商品在整个瀑布流中的位置信息
 */
function getTriggerItemInfo({ column0, column1, triggerItemIndex }) {
  // 查找触发源商品在两个列中的位置
  const column0Index = column0.findIndex(item => item == triggerItemIndex)
  const column1Index = column1.findIndex(item => item == triggerItemIndex)
  // 触发源商品所在列的列号
  const triggerColumnNumber = column0Index > -1 ? 0 : column1Index > -1 ? 1 : -1
  // 触发源商品插入位置索引
  const triggerSpliceIndex = column0Index > -1 ? column0Index : column1Index > -1 ? column1Index : -1

  const isTriggerInColumn0 = triggerColumnNumber === 0
  const isTriggerInColumn1 = triggerColumnNumber === 1

  if (triggerColumnNumber === -1) {
    console.error('触发源商品不在瀑布流中')
    return
  }

  if (triggerSpliceIndex === -1) {
    console.error('触发源商品在瀑布流中的插入位置索引错误')
    return
  }

  // 插入成功后商品在瀑布流中的位置索引
  const insertedItemIndex = isTriggerInColumn0 ? column0[triggerSpliceIndex] : column1[triggerSpliceIndex]

  return {
    isTriggerInColumn0,
    isTriggerInColumn1,
    triggerSpliceIndex,
    insertedItemIndex
  }
}

/**
 * 格式化插入商品信息 
 */
function formatInsertItems({ insertItems, insertedItemIndex, dynamicInsertNum, targetSlotIndex, triggerGoodsId }) {
  const insertItemsFormated = insertItems.map((item, dynamicItemsIndex) => {

    // getMultiInsertItemIndex 动态插入商品在瀑布流中的真实位置
    let getMultiInsertItemIndex = insertedItemIndex


    if (dynamicInsertNum === 1) {
      getMultiInsertItemIndex = insertedItemIndex + 2
    }

    if (dynamicInsertNum >= 2) {
      getMultiInsertItemIndex = insertedItemIndex + dynamicItemsIndex + 1
    }

    return {
      targetSlotIndex,
      triggerGoodsId,
      insertItem: item,
      insertedItemIndex: getMultiInsertItemIndex,
      dynamicItemsIndex,
    }
  })

  return insertItemsFormated
}

/**
 * 修复高度不一致的情况
 */
function fixedOverHeight({ dynamicInsertNum, column0, column1 }) {
  // 动态插入商品数量为双数，不需要补高度差
  if (dynamicInsertNum % 2 === 0) {
    return
  }

  // 两侧高度有差异了，高补低（即将插入短侧则不补）
  if (column0.length - column1.length >= 2) {
    let itemIndex = column0.pop()
    column1.push(itemIndex)
    return
  }
  if (column1.length - column0.length >= 2) {
    let itemIndex = column1.pop()
    column0.push(itemIndex)
    return
  }
}

/**
 * 动态插入瀑布流商品
 * 点推默认算法
 */
function dynamicInsert({ column0, column1, items, newDynamicItems }) {
  if (!newDynamicItems?.length) return // 插坑数据为空不执行
  // 获取最新的动态插坑数据 也就是数组新增的最后一项
  const newDynamicItem = newDynamicItems[newDynamicItems.length - 1]
  const triggerGoodsId = newDynamicItem.triggerGoodsId
  let insertItems = newDynamicItem.insertItems
  const dynamicInsertNum = insertItems.length
  const targetSlotIndex = newDynamicItem.targetSlotIndex

  const isTargetSlotIndexType = targetSlotIndex != null

  // 触发源商品在瀑布流中的位置
  // 两种使用方式
  // 1. 提供触发源商品的 goods_id
  // 2. 提供瀑布流中的位置索引
  let triggerItemIndex = null

  if (triggerGoodsId) {
    triggerItemIndex = items.findIndex(item => item.goods_id == triggerGoodsId)
  }

  if (isTargetSlotIndexType) {
    triggerItemIndex = targetSlotIndex
    // 提供瀑布流中的位置索引的方式仅支持指定位置单个商品
    insertItems = [insertItems[0]]
  }

  if (triggerItemIndex === null) {
    console.error('触发源商品索引获取失败')
    return
  }

  const {
    isTriggerInColumn0,
    isTriggerInColumn1,
    triggerSpliceIndex,
    insertedItemIndex
  } = getTriggerItemInfo({ column0, column1, triggerItemIndex })

  // 格式化插入商品信息
  const insertItemsFormated = formatInsertItems({ insertItems, insertedItemIndex, dynamicInsertNum, targetSlotIndex, triggerGoodsId })

  const columns = isTriggerInColumn0 ? column0 : column1

  if (columns) {
    // 插入单一商品
    if (dynamicInsertNum === 1) {
      if (isTargetSlotIndexType) {
        // 指定位置插入
        columns.splice(triggerSpliceIndex, 0, ...insertItemsFormated)
      } else {
        // 动态点推逻辑
        columns.splice(triggerSpliceIndex + 1, 0, ...insertItemsFormated)
      }
    }

    // 插入多个商品 && 点击的是 column0 的商品
    if (dynamicInsertNum >= 2 && isTriggerInColumn0) {
      /**
       * 将插入的商品分为偶数和奇数两组 根据点击的商品所在列的索引位置，分别插入到对应的列中
       */
      const [evenInsertItemsFormated, oddInsertItemsFormated] = insertItemsFormated.reduce(
        (result, item, index) => {
          result[index % 2 === 0 ? 0 : 1].push(item)
          return result
        },
        [[], []] // Initialize two arrays: one for even-index, one for odd-index
      )

      evenInsertItemsFormated.map((item, index) => {
        column1.splice(triggerSpliceIndex + index, 0, item)
      })

      oddInsertItemsFormated.map((item, index) => {
        column0.splice(triggerSpliceIndex + index + 1, 0, item)
      })
    }

    // 插入多个商品 && 点击的是 column1 的商品
    if (dynamicInsertNum >= 2 && isTriggerInColumn1) {
      /**
       * 将插入的商品分为偶数和奇数两组 根据点击的商品所在列的索引位置，分别插入到对应的列中
       */
      const [evenInsertItemsFormated, oddInsertItemsFormated] = insertItemsFormated.reduce(
        (result, item, index) => {
          result[index % 2 === 0 ? 0 : 1].push(item)
          return result
        },
        [[], []] // Initialize two arrays: one for even-index, one for odd-index
      )

      evenInsertItemsFormated.map((item, index) => {
        column0.splice(triggerSpliceIndex + index + 1, 0, item)
      })

      oddInsertItemsFormated.map((item, index) => {
        column1.splice(triggerSpliceIndex + index + 1, 0, item)
      })
    }
  }

  // 每次插坑后，需要重新计算一次高度，防止出现高度不一致的情况
  fixedOverHeight({ dynamicInsertNum, column0, column1 })
}

export {
  dynamicInsert
}
