<template>
  <div class="waterfall">
    <div
      v-for="column in columns"
      ref="column"
      :key="column.i"
      :data-index="column.i"
      class="waterfall-column"
    >
      <!-- 别用goodsId做key, 针对泛列表的点后推（参见: wiki：pageId=1470253023），可能会出现点推一样的商品出来 -->
      <!-- 用goods_id做索引页面waterFall报错Duplicate keys detected: -->
      <div
        v-for="i in column.indexes"
        :key="getKey(i)"
        :data-key="getKey(i)"
        class="waterfall-item"
      >
        <slot
          v-if="isDynamic(i)"
          name="dynamic"
          :item="i.insertItem"
          :index="i.index"
          :insertedItemIndex="i.insertedItemIndex"
        ></slot>

        <slot
          v-else
          :item="items[i]"
          :index="i"
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, watch } from 'vue'
import { dynamicInsert } from './plugins/dynamicInsert'

export default defineComponent({
  name: 'WaterFallV2',
  emits: ['mounted', 'addItemDone', 'update:deleteItemsFlag'],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    /**
     * {
     *  columns: 2,
     *  itemQuantity: 6 服务端渲染的商品数量
     * }
     */
    ssr: {
      type: Object,
      default: () => ({
        columns: 2,
        itemQuantity: 6
      })
    },
    initWaterFall: {
      type: Boolean,
      default: false
    },
    strictLeftToRight: {
      type: Boolean,
      default: false,
    },
    deleteItemsFlag: {
      type: Boolean,
      default: false,
    },
    /**
     * 动态插入瀑布流商品
     * triggerGoodsId 与 targetSlotIndex 二选一
     * 格式:
     * [
     *  {
     *    triggerGoodsId: '' // 商品id
     *    targetSlotIndex: 0 // 目标插入的位置
     *    insertItems: [] // 插入的商品
     *  },
     * ]
     */
    dynamicItems: {
      type: Array,
      default: () => []
    },
    /**
     * 覆盖动态插入瀑布流商品默认算法
     */
    overrideDynamicInsert: {
      type: Function,
      default: dynamicInsert
    }
  },
  data() {
    return {
      columns: [],
      cursor: 0,
      nextIndex: 0,
    }
  },
  computed: {
    itemQuantity() {
      return this.ssr?.itemQuantity || 0
    },
    count() {
      return this.ssr?.columns || 0
    },
  },
  watch: {
    itemQuantity: {
      handler(val) {
        this.cursor = val
      },
      immediate: true
    },
    items: {
      async handler(newVal, oldVal) {
        const itemsLenChange = newVal?.length !== oldVal?.length // items的数量变换
        // 新增initWaterFall判断，某些筛选条件下，前后items的数量是一样的，这种情况也需要更新瀑布流
        if ((newVal?.length && itemsLenChange) || this.initWaterFall) {

          // 删除操作时，只进行删除操作，不进行添加操作
          if (this.deleteItemsFlag && this.cursor >= this.items.length) {
            const deleteStartIndex = newVal.length - 1
            this.columns.forEach(column => {
              const deleteIndex = column.indexes.findIndex(index => index > deleteStartIndex)
              column.indexes.splice(deleteIndex) // 删除大于deleteStartIndex的index
            })
            this.cursor = newVal.length
            this.$emit('update:deleteItemsFlag', false)
            return
          }
          
          // 在切换图文导航 + 网速较慢的极端场景下, 会偶发在上一页 columns 里塞满了 index
          // 但是 initWaterFall 又没有被长时间置为 true, 导致 init 的过程被跳过了, 出现空白商品项的情况
          // 这里增加一下个判断
          if (this.initWaterFall || this.cursor >= this.items.length) {
            this.initInfo()
          }
          if (!this.columns.length) {
            this.initColumns()
          }
          this.addItem(this.nextIndex, 'firstAddItem')
        }
      },
      immediate: true
    },
  },
  beforeUnmount() {
    this._dynamicItemsWatcher?.()
  },
  mounted() {
    this.$emit('mounted')
    this._dynamicItemsWatcher = watch(() => [this.dynamicItems, this.dynamicItems.length], (newData, oldData) => {
      const newDynamicItems = newData[0]
      const oldDynamicItems = oldData[0]
      // 相同引用则为增量的动态插入
      if (newDynamicItems === oldDynamicItems) {
        this.handleDynamicItemsChange(newDynamicItems)
        return
      }

      // 不同引用则为全量的动态插入
      newDynamicItems.forEach(newDynamicItem => {
        this.handleDynamicItemsChange([newDynamicItem])
      })
    })
  },
  methods: {
    initInfo() {
      this.columns = []
      this.cursor = this.itemQuantity
    },
    initColumns() {
      if (this.count > 0) {
        const columns = this.createColumns(this.count)
        let i = 0
        for (i = 0; i < Math.min(this.itemQuantity, this.items?.length); i++) {
          columns[i % this.count].indexes.push(i)
        }
        // 修正cursor有 极端插坑数据 = itemQuantity 会跳过
        this.cursor = i
        this.columns = columns
        this.nextIndex = 0
      }
    },
    // 需要创建几列
    createColumns(count) {
      const columns = []
      for (let i = 0; i < count; i++) {
        columns.push({ i: i, indexes: [] })
      }
      return columns
    },
    async addItem(nextIndex, status) {
      // console.log(this.cursor, this.items.length, nextIndex, 'nextIndexnextIndexnextIndex');
      if (typeof window === 'undefined') return

      // 瀑布流塞item完成
      const itemsLen = this.items.length
      if (this.cursor >= itemsLen) {
        this.$emit('addItemDone')
        // 非递归addItem进到这里，才设置nextIndex
        if (status === 'firstAddItem') {
          this.nextIndex = (nextIndex + itemsLen) % this.count
        }
        return
      }

      if (!this.$refs.column) {
        await nextTick()
      }

      const columnDiv = this.$refs.column
      if (!Array.isArray(columnDiv) || !columnDiv.length) {
        return
      }

      let columnIndex

      if (this.strictLeftToRight) {
        columnIndex = nextIndex
        this.nextIndex = (nextIndex + 1) % this.count
      } else {
        let noSize = true
        const target = columnDiv.reduce((prev, curr) => {
          const prevHeight = prev.getBoundingClientRect().height
          const currHeight = curr.getBoundingClientRect().height
          if (prevHeight !== 0 || currHeight !== 0) {
            noSize = false
          }
          return prevHeight < currHeight ? prev : curr
        })
        columnIndex = target.dataset.index
        // 若无高度，则各个列轮流添加项nextIndexnextIndexnextIndex
        if (noSize) {
          columnIndex = nextIndex
          this.nextIndex = (nextIndex + 1) % this.count
        }
      }

      const column = this.columns[columnIndex]
      if (this.items[this.cursor]) {
        column.indexes.push(this.cursor)
        this.cursor++
        await nextTick()
        this.addItem(this.nextIndex)
      }
    },
    handleDynamicItemsChange(newDynamicItems) {
      const column0 = this.columns[0].indexes // 瀑布流左列
      const column1 = this.columns[1].indexes // 瀑布流右列
      const items = this.items // 瀑布流所有商品

      this.overrideDynamicInsert({
        column0,
        column1,
        items,
        newDynamicItems
      })
    },
    isDynamic(i) {
      return i?.triggerGoodsId || typeof i?.targetSlotIndex === 'number'
    },
    getKey(i) {
      if (this.isDynamic(i)) {
        return `trigger-from-${i.triggerGoodsId || i.targetSlotIndex}-${i.dynamicItemsIndex}`
      }

      return i
    }
  },
})
</script>

<style lang="less" scoped>
.waterfall {
  .flexbox();
  .space-between();
  align-items: flex-start;
}
.waterfall-column {
  .flexbox();
  flex-direction: column;
}
</style>

